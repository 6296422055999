<template>
  <div>
    <tab-headers> Settings</tab-headers>

    <div class="bg-white pa-2 px-4 rounded-md shadow-md mt-10">
      <a-tabs default-active-key="2">
        <a-tab-pane key="1" tab="Profile">
          <div class="px-4 pt-4 pb-6">
            <h3 class="font-semibold text-lg mb-2">Change Password</h3>
            <a-form-model
              ref="securityForm"
              :rules="rules"
              :model="password"
              layout="vertical"
            >
              <a-form-model-item has-feedback label="Old Password" prop="'old">
                <a-input
                  v-model="password.old"
                  type="password"
                  placeholder="Old Password"
                ></a-input>
              </a-form-model-item>
              <a-form-model-item has-feedback label="New Password" prop="new">
                <a-input
                  v-model="password.new"
                  type="password"
                  placeholder="New Password"
                ></a-input>
              </a-form-model-item>
              <a-form-model-item
                has-feedback=""
                label="Confirm New Password"
                prop="confirm"
              >
                <a-input
                  v-model="password.confirm"
                  type="password"
                  placeholder="Retype New Password"
                ></a-input>
              </a-form-model-item>
            </a-form-model>
            <a-button
              :loading="password.updating"
              type="primary"
              @click="changePassword"
              >Update</a-button
            >
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Area of Expertise">
          <area-of-expertise />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Dispute category">
          <Complainant />
        </a-tab-pane>
        <a-tab-pane key="4" tab="Other Settings">
          <div class="px-4 pt-4 pb-6">
            <h3 class="font-semibold text-lg mb-2">System Settings</h3>
            <a-form-model :model="settings" layout="vertical">
              <template v-for="setting in settingsData">
                <a-form-model-item
                  :key="setting._id"
                  label="Dispute Processing Fee"
                  prop="'processingFee"
                >
                  <a-input
                    v-model="settings.processingFee"
                    type="number"
                    placeholder="Dispute Processing Fee"
                  ></a-input>
                </a-form-model-item>
              </template>
            </a-form-model>
            <a-button
              :loading="settings.updating"
              type="primary"
              @click="updateSettings"
              >Update</a-button
            >
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import AreaOfExpertise from '../../components/admin/settings/areaOfExpertise.vue'
import Complainant from '../../components/admin/settings/disputeComplient.vue'

import TabHeaders from '../../components/typography/TabHeaders.vue'
import authApi from '../../api/auth'
import siteDataApi from '../../api/site_data'

export default {
  name: 'Settings',
  components: { TabHeaders, AreaOfExpertise, Complainant },
  layout: 'admindashboard',
  data() {
    const passwordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.password.confirm !== '') {
          this.$refs.securityForm.validateField('conform')
        }
        callback()
      }
    }
    const confirmPasswordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.password.new) {
        callback(new Error("Password inputs don't match!"))
      } else {
        callback()
      }
    }
    return {
      password: {
        old: '',
        new: '',
        confirm: '',
        updating: false,
      },
      settingsData: [],
      settings: {
        processingFee: 500,
        updating: false,
      },
      rules: {
        old: [
          {
            message: 'Please enter you present password',
            required: true,
          },
        ],
        new: [
          {
            message: 'Password cannot be empty',
            required: true,
          },
          {
            message: 'Password must be at least 6 characters',
            min: 6,
          },
          {
            trigger: 'change',
            validator: passwordValidator,
          },
        ],
        confirm: [
          {
            required: true,
            message: 'Enter your password again',
          },
          {
            validator: confirmPasswordValidator,
            trigger: 'change',
          },
        ],
      },
    }
  },
  created() {
    this.getSetting()
  },
  methods: {
    async changePassword() {
      this.password.updating = true
      const req = await authApi(this.axios).changePassword({
        oldPassword: this.password.old,
        password: this.password.new,
        confirmPassword: this.password.confirm,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to update profile',
          description: req.message,
        })
        this.password.updating = false
        return
      }
      this.$notification.success({
        message: 'Password changed successful',
        description: req.message,
      })
      this.password.updating = false
      this.password = {
        new: '',
        old: '',
        confirm: '',
      }
    },
    async getSetting() {
      const req = await siteDataApi(this.axios).get({ type: 'settings' })
      this.settingsData = req.data.data
      this.settings.processingFee = this.settingsData.find((data) =>
        Object.keys(data).includes('processingFee')
      ).processingFee
    },
    async createSettings() {
      this.settings.updating = true
      const req = await siteDataApi(this.axios).create({
        type: 'settings',
        title: 'processingFee',
      })
      if (req.error) {
        this.$notification.error({ message: 'Error', description: req.message })
      }
      this.settings.updating = false
    },
    async updateSettings() {
      this.settings.updating = true
      const req = await siteDataApi(this.axios).update({
        type: 'settings',
        typeId: this.settingsData.find((data) =>
          Object.keys(data).includes('processingFee')
        )._id,
        data: {
          processingFee: this.settings.processingFee,
        },
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.message,
        })
        this.settings.updating = false
        return
      }
      this.$notification.success({
        message: 'Updated',
        description: 'Processing fees updated successfully',
      })

      this.getSetting()
      this.settings.updating = false
    },
  },
}
</script>
