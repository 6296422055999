<template>
  <div>
    <div class="md:flex items-start space-x-10">
      <div class="md:w-6/12">
        <div class="flex flex-col">
          <a-list
            :loading="gettingSpecialty"
            item-layout="horizontal"
            :data-source="expertise"
          >
            <a-list-item slot="renderItem" key="item._id" slot-scope="item">
              <div class="flex items-center justify-between w-full">
                <span>{{ item.title }}</span>
                <span>
                  <a-icon
                    class="text-lg cursor-pointer"
                    type="delete"
                    @click="showDeleteConfirm(item._id)"
                  />
                </span>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div class="md:w-6/12">
        <h3 class="mb-5 text-lg font-semibold text-gray-700">
          Add a new category for disputes
        </h3>
        <a-form-model
          ref="form"
          :rules="rules"
          :model="form"
          layout="vertical"
          hide-required-mark
        >
          <a-form-model-item label="Title" prop="title">
            <a-input
              v-model="form.title"
              placeholder=" Compliant Dispute category"
              prop="title"
            />
          </a-form-model-item>

          <div class="flex items-end justify-end">
            <a-button
              :loading="addingExpertise"
              type="primary"
              @click="onSubmit"
            >
              Add
            </a-button>
          </div>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import siteDataApi from '../../../api/site_data'
export default {
  name: 'AreaOfExpertise',
  data() {
    return {
      form: { title: '' },
      rules: {
        title: {
          required: true,
          message: 'Please add some text',
        },
      },
      expertise: [],
      deletingSpecialty: 'false',
      addingExpertise: false,
      gettingSpecialty: false,
    }
  },
  created() {
    this.getSpecialty()
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) this.addExpertise()
        else return false
      })
    },
    async getSpecialty() {
      this.gettingSpecialty = true
      const req = await siteDataApi(this.axios).get(
        { type: 'complaintype' },
        { limit: 0, order: 'asc', orderBy: 'title' }
      )
      if (req.error) {
        this.$notification.error({
          message: 'Unable to get Compliant Dispute category',
          description: req.message,
        })
        this.gettingSpecialty = false
        return
      }
      this.expertise = req.data.data.sort((a, b) => {
        return a.title.toLowerCase() < b.title.toLowerCase()
          ? -1
          : a.title.toLowerCase() > b.title.toLowerCase()
          ? 1
          : 0
      })
      this.gettingSpecialty = false
    },
    async deleteSpecialty(id) {
      this.deletingSpecialty = true
      const req = await siteDataApi(this.axios).delete({
        type: 'complaintype',
        typeId: id,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to remove Compliant Dispute category',
          description: req.message,
        })

        this.deletingSpecialty = false
        return
      }
      this.$notification.success({
        message: 'Compliant Dispute category removed',
        description: req.message,
      })
      this.getSpecialty()
      this.deletingSpecialty = false
    },
    async addExpertise() {
      this.addingExpertise = true
      const req = await siteDataApi(this.axios).create({
        type: 'complaintype',
        title: this.form.title,
      })
      if (req.error) {
        this.$store.dispatch('notification/updateNotification', {
          type: 'error',
          message: 'Failed to add dispute category',
        })
        this.addingExpertise = false
        return
      }
      this.$notification.success({
        message: 'Compliant Dispute category added',
        // description: req.message,
      })
      this.getSpecialty()
      this.form.title = ''
      this.addingExpertise = false
    },
    showDeleteConfirm(id) {
      this.$confirm({
        title: 'Are you sure delete this Compliant Dispute category?',
        content: 'Confirm this delete',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.deleteSpecialty(id)
        },
        onCancel() {},
      })
    },
  },
}
</script>
